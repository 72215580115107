import { Col, Row, Select } from "antd";
import base62 from "base62";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { ApiEndpoints } from "../api/endpoints";
import UnsubscribeForm from "../components/Unsubscribe/UnsubscribeForm";

const localeName = {
  fr: "🇫🇷 Français",
  en: "🇺🇸 English",
  es: "🇪🇸 Español",
};

export default function Index() {
  const { id: base62VenueId } = useParams<any>();
  const { push } = useHistory();
  const { t, i18n } = useTranslation();
  const venueId = base62.decode(base62VenueId);
  const {
    data: venue,
    isLoading,
    isError,
  } = useQuery(
    `venue/${venueId}`,
    () =>
      fetch(ApiEndpoints.venues.findOne(venueId)).then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      }),
    {
      retry: 1,
    }
  );

  const {
    mutate: unsubscribe,
    isSuccess,
    isLoading: isUnsubscribeLoading,
  } = useMutation<any, Error, any>("messages/sms/unsubscribe", (body: string) =>
    fetch(ApiEndpoints.messages.createUnsubscribe(), {
      method: "POST",
      body: JSON.stringify(body)!,
      headers: {
        "Content-Type": "application/json",
      },
    })
  );

  React.useEffect(() => {
    if (isError) {
      window.location.href = "https://sofy.fr";
    }
  }, [isError]);

  React.useEffect(() => {
    isSuccess && push("/success");
  }, [isSuccess, push]);

  const handleSubmit = (phoneNumber: string) => {
    unsubscribe({
      venueId,
      phoneNumber,
    });
  };

  return isLoading ? null : (
    <div className="relative">
      <div className="absolute top-0 right-0">
        <Select value={i18n.language} onChange={(l) => i18n.changeLanguage(l)}>
          {Object.keys(i18n.store.data).map((it: any) => (
            <Select.Option value={it}>
              {localeName[it as "en" | "fr" | "es"]}
            </Select.Option>
          ))}
        </Select>
      </div>
      <h3 style={{ textAlign: "center" }} className="mt-10">
        {t("sorryToSeeYouGo")}
      </h3>
      <p className="text-center mt-5">
        {t("unsubscribeFrom", { venue: venue?.venueName })}
        <br />
      </p>
      <Row justify="center" className="mt-10">
        <Col md={16} xl={10} xs={24}>
          <UnsubscribeForm
            onSubmit={handleSubmit}
            isLoading={isUnsubscribeLoading}
          />
        </Col>
      </Row>
    </div>
  );
}
