import { Button, Col, Form, Row } from "antd";
import { trimStart } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import AppFormInputPhoneNumber from "../App/Form/Input/AppFormInputPhoneNumber";

export interface UnsubscribeFormProps {
  onSubmit: (phoneNumber: string) => void;
  isLoading: boolean;
}

export default function UnsubscribeForm({
  onSubmit,
  isLoading,
}: UnsubscribeFormProps) {
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const { t } = useTranslation();
  const handleSubmit = (values: any) => {
    onSubmit(trimStart(values.phoneNumber.replace("+", "")));
  };

  return (
    <Form onFinish={handleSubmit} layout="vertical">
      <Form.Item
        name="phoneNumber"
        label={t("mobilePhoneNumber")}
        rules={[
          {
            required: true,
            message: t("fillPhoneNumber"),
          },
        ]}
        required
      >
        <AppFormInputPhoneNumber
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
      </Form.Item>
      <Row justify="center">
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {t("unsubscribe")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
