import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function Success(): ReactElement {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="text-center">{t("unsubscribeSuccess")}</h3>
    </div>
  );
}
