export const ApiEndpoints = {
  venues: {
    findOne: (id: number) =>
      process.env.REACT_APP_API_BASE_URL + `/venues/${id}`,
  },

  messages: {
    createUnsubscribe: () =>
      process.env.REACT_APP_API_BASE_URL + '/messages/sms/unsubscribe',
  },
};
