import CountryPhoneInput, {
  ConfigProvider,
  CountryPhoneInputProps,
  CountryPhoneInputValue,
} from "antd-country-phone-input";
import "antd-country-phone-input/dist/index.css";
import parsePhoneNumberFromString from "libphonenumber-js";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import fr from "world_countries_lists/data/fr/world.json";

//       import { useTranslation } from 'react-i18next';

interface AppFormInputPhoneNumberProps
  extends Omit<CountryPhoneInputProps, "value" | "onChange"> {
  value?: string;
  onChange?: (value: string) => void | Promise<void>;
}

function AppFormInputPhoneNumber({
  value,
  onChange,
  ...props
}: AppFormInputPhoneNumberProps): ReactElement {
  //   const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] =
    React.useState<CountryPhoneInputValue>();
  const { t } = useTranslation();
  React.useEffect(() => {
    if (value) {
      const pn = parsePhoneNumberFromString(value);
      if (pn?.isValid()) {
        setPhoneNumber({
          code: parseInt(pn.countryCallingCode as string),
          phone: pn.nationalNumber as string,
        });
      }
    }
  }, [value]);

  React.useEffect(() => {
    if (phoneNumber) {
      const pn = parsePhoneNumberFromString(
        "+" + phoneNumber.code + phoneNumber.phone
      );
      if (pn?.isValid()) {
        onChange?.(pn.number as string);
      } else {
        onChange?.("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  return (
    <ConfigProvider locale={fr}>
      <CountryPhoneInput
        size="small"
        value={phoneNumber}
        onChange={setPhoneNumber}
        selectProps={{
          placeholder: t("countryCode"), //t('bits.callingCode'),
        }}
        {...props}
      />
    </ConfigProvider>
  );
}

export default AppFormInputPhoneNumber;
