import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { useIsMobile } from '../hooks/is-mobile';

interface LayoutDefaultProps {
  children?: ReactNode;
}

export default function LayoutDefault({
  children,
}: LayoutDefaultProps): ReactElement {
  const isMobile = useIsMobile();

  const rootClasses = classNames([
    'mx-auto rounded my-3',
    {
      'w-full': isMobile,
      'w-8/12': !isMobile,
      'bg-gray-100': !isMobile,
    },
  ]);

  return (
    <>
      <div className={rootClasses}>
        <div className={`${isMobile ? 'p-4' : 'p-8'}`}>{children}</div>
      </div>

      <div className='absolute bottom-4 w-full text-center text-sm text-gray-400'>
        <small>
          Campagne envoyée via{' '}
          <a className='text-gray-400 no-underline' href='https://sofy.fr'>
            Sofy ©
          </a>
        </small>
      </div>
    </>
  );
}
